import React from "react";
import { logFirebaseEvent } from "../../utils/analytics";
import { useNavigate } from "react-router-dom";
import YoutubeEmbed from "../../Components/common/YoutubeEmbed";
import LinkedIn from "../../assets/image/svg/linked_in_blue.svg";
import LinkedInBlue from "../../assets/image/svg/linkedin-create-resume.svg";
import Plus from "../../assets/image/plus-blue.png";
import Logo from "../../assets/image/svg/Logo.svg";
import Edmond from "../../assets/image/linkedin-image/Edmond.png";
import Joseph from "../../assets/image/linkedin-image/Joseph.png";
import Sushant from "../../assets/image/linkedin-image/Sushant.png";

const LinkedInFollow = () => {
  const navigate = useNavigate();
  return (
    <div className="app-container">
      <div className="coach-header p-2">
        <a onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" width={170} className="mt-1" />
        </a>
      </div>

      <section className="mx-3 mx-md-5 mt-70 pt-5">
        <h1
          className="text-center text-white fs-1"
          style={{ lineHeight: "2.75rem" }}
        >
          Ready to Land Your Dream Job? Get $3,500 <br /> Worth of Proven
          Strategies — For $0!
        </h1>
        <p className="text-center text-white fs-2 mt-3">
          I&apos;m Amit Patel, and I&apos;m about to share something most <br />
          career coaches would charge thousands of dollars for — <br /> but
          it&apos;s yours completely free.
        </p>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6">
            <YoutubeEmbed embedId="Jr_IsL9h1Lo" mute={0} />
          </div>

          <div className="d-flex justify-content-center align-items-center my-4">
            <a
              href="https://www.linkedin.com/in/aamitmit/"
              className="linkedin-follow-btn2 mx-3 rounded-pill w-150"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logFirebaseEvent("EVERYTHING_QUICK_FREE_FOLLOW_PLUS_BTN");
              }}
            >
              Follow
              <img src={Plus} alt="plus_icon" height={20} className="mt-n3" />
            </a>
            <a
              href="https://www.linkedin.com/in/aamitmit/"
              className="linkedin-follow-btn2 align-items-center rounded-pill w-150"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logFirebaseEvent("EVERYTHING_QUICK_FREE_LINKEDIN_BTN");
              }}
            >
              Linked
              <img
                src={LinkedIn}
                alt="linkedIn"
                height={20}
                className="mt-n4"
              />
            </a>
          </div>

          <p className="text-center text-white mt-3 fs-3">
            {`- Join 6,000+ Professionals Following Amit on LinkedIn -`}
          </p>
          <hr className="custom-hr" />
        </div>
      </section>

      <section className="testimonials pt-4">
        <h3 className="text-white fs-1">Client Testimonials</h3>
        <p className="text-white fs-3">
          Hear from People Who Landed Their Dream Jobs with My Help
        </p>

        <div className="testimonial-cards container">
          <div className="row gy-3">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial-card">
                <img
                  src={Edmond}
                  alt="Edmond"
                  width={170}
                  className="rounded-pill mb-3"
                />
                <p className="fs-5">
                  {`"Amit's got a knack for career coaching that really sets him
                  apart. With hands-on experience in hiring for big names like
                  Macy's and CBS, he knows the game from the inside. This unique
                  angle means he can give tips that aren't just theoretical but
                  based on what big companies actually want. He's clued into the
                  ins and outs of corporate hiring, so with him, you're getting
                  insights that can really make a difference. Think of Amit not
                  just as a coach but as your industry insider buddy. Teaming up
                  with him? That's a smart move for your career."`}
                </p>
                <p className="fs-14 mt-3">{`– Edmond McLaughlin`}</p>
                <p className="fs-14">Digital Services Expert (Engineering)</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial-card">
                <img
                  src={Joseph}
                  alt="Joseph"
                  width={170}
                  className="rounded-pill mb-3"
                />
                <p className="fs-5">
                  {`"Amit stands out as a beacon of proficiency in the realm of career coaching. His adept understanding of industry dynamics combined with his personalized approach makes him invaluable to anyone seeking to elevate their professional journey. He masterfully guides individuals with actionable insights tailored to their unique career aspirations. His genuine passion and commitment to helping others achieve their goals are evident in the results he consistently delivers. Anyone fortunate enough to benefit from Amit's guidance will undoubtedly find themselves better positioned for success."`}
                </p>
                <p className="fs-14 mt-3">{`– Joseph Toyoshima`}</p>
                <p className="fs-14">Director of Digital Analytics</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="testimonial-card">
                <img
                  src={Sushant}
                  alt="Amit_Patel"
                  width={170}
                  className="rounded-pill mb-3"
                />
                <p className="fs-5">
                  {`"Amit helped review my resume and re-word sections to highlight my experience in a better and readable format. I am extremely pleased with the new resume and excited to start applying for jobs with Amit's help. His expertise offered a second set of eyes in pointing out resume edits. By making the suggested edits in my resume, it places my experience, accomplishments and key results in the forefront. I would definitely recommend Amit's services in your career transition journey."`}
                </p>
                <p className="fs-14 mt-3">{`– Sushant Arora`}</p>
                <p className="fs-14">Product Manager</p>
              </div>
            </div>
          </div>
        </div>

        <a
          href="https://www.linkedin.com/company/careerclaw/"
          className="join-btn"
          rel="noreferrer"
          target="_blank"
          onClick={() => {
            logFirebaseEvent("EVERYTHING_QUICK_FREE_JOIN_US_BTN");
          }}
        >
          Join Us
          <img
            src={LinkedInBlue}
            alt="plus_icon"
            height={24}
            className="rounded ms-1"
          />
        </a>
      </section>
    </div>
  );
};

export default LinkedInFollow;
